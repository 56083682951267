@import '/src/assets/theme/variables.scss';
@import '~@angular/material/theming';
@import '~bootstrap/scss/bootstrap';
/* Importing Datepicker SCSS file. */
@import '~ngx-bootstrap/datepicker/bs-datepicker';

@include mat-core();

$mat-indigo: (
  50: #e2e7ea,
  100: #b8c3ca,
  200: #889ba7,
  300: #587384,
  400: #355569,
  500: #11374f,
  600: #0f3148,
  700: #0c2a3f,
  800: #0a2336,
  900: #051626,
  A100: #0a2336,
  A200: #0a2336,
  A400: #0a2336,
  A700: #0a2336,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

$mat-pink: $mat-indigo;
$mat-accent: $mat-indigo;

$contentCreatorPortal-primary: mat-palette($mat-indigo);
$contentCreatorPortal-accent: mat-palette($mat-pink, A200, A100, A400);

$contentCreatorPortal-warn: mat-palette($mat-red);

$contentCreatorPortal-theme: mat-light-theme(
  (
    color: (
      primary: $contentCreatorPortal-primary,
      accent: $contentCreatorPortal-accent,
      warn: $contentCreatorPortal-warn
    )
  )
);

@include angular-material-theme($contentCreatorPortal-theme);

.container {
  max-width: 1200px;
}

html {
  scroll-behavior: smooth;
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'HelveticaNeueMedium', sans-serif;
  color: $primary-dark-color;
  height: 100%;
}

//Tree Style
.toggle-children {
  background-image: url(/assets/icons/menu-plus.svg);
  height: 17px;
  width: 17px;
  top: 2px;
}

//Style for Folder icon Highlight on Close and Open state
.tree-node {
  &.tree-node-collapsed {
    > tree-node-wrapper {
      > div > .node-content-wrapper span {
        display: flex;
        align-items: flex-end;

        .folder-icon {
          margin-right: 10px;
          background-image: url(/assets/icons/folder-outline.svg);
          background-size: contain;
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  &.tree-node-expanded {
    > tree-node-wrapper {
      > div > .node-content-wrapper span {
        display: flex;
        align-items: flex-end;

        .folder-icon {
          margin-right: 10px;
          background-image: url(/assets/icons/folder-opened.svg);
          background-size: contain;
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}

.multimedia-cont {
  .toggle-children {
    background-image: url(/assets/icons/plus-icon.svg);
    height: 12px;
    width: 12px;
    top: 0px;
  }

  .toggle-children-wrapper-expanded .toggle-children {
    background-image: url(/assets/icons/minimize-icon.svg);
    transform: none;
  }
}

.angular-tree-component {
  width: auto;
  min-width: 100%;
}

.toggle-children-placeholder {
  display: none;
}

.toggle-children-wrapper-expanded .toggle-children {
  background-image: url(/assets/icons/menu-minus.svg);
  transform: none;
}

.node-content-wrapper {
  width: 100%;
  padding: 0 4.5px;
  @include transition();

  &:hover,
  &.node-content-wrapper-active {
    background: transparent;
    box-shadow: none;

    .tree-text {
      color: $primary-color;
    }
  }

  &.node-content-wrapper-focused {
    background: transparent;
    box-shadow: none;
  }
}

tree-viewport {
  overflow: initial;
}

.tree-node-expanded .pagination-cont,
.tree-node-expanded .search-cont {
  display: flex;
}

.tree-node-collapsed .pagination-cont,
.tree-node-collapsed .search-cont {
  display: none;
}

.node-content-wrapper-active,
.node-content-wrapper.node-content-wrapper-active:hover,
.node-content-wrapper-active.node-content-wrapper-focused {
  background-color: transparent;
  text-decoration: none;
  color: $primary-color;

  .total-page {
    text-decoration: none;
  }
}

.form-control {
  font-size: 0.8rem;
  padding: 20px 10px;
  border-radius: 0;
  border: 1.5px solid $gray-scale-04;
  width: 260px;
  height: 45px;

  &::placeholder {
    color: $gray-scale-04;
  }
}

.form-label {
  margin-bottom: 8px;
  margin-top: 10px;
  font-size: 16px;
  color: $bayer-dark-blue;
  font-family: 'HelveticaNeueMedium';
  text-transform: capitalize;
}

.filter-menu-cont .form-label {
  margin-bottom: 5px;
  margin-top: 15px;
  font-family: 'HelveticaNeueLight';
}

//Admin Font Styles
.b-heading-01-admin {
  font-size: 26px;
  font-family: 'HelveticaNeueLight';
}

.b-heading-03-admin {
  font-size: 22px;
  font-family: 'HelveticaNeueMedium';
}

//Font Styles
.b-heading-01 {
  font-size: 28px;
  font-family: 'HelveticaNeueLight';
}

.b-heading-02 {
  font-size: 24px;
  font-family: 'HelveticaNeueBold';
}

.b-heading-03 {
  font-size: 24px;
  font-family: 'HelveticaNeueMedium';
}

.b-heading-04 {
  font-size: 24px;
  font-family: 'HelveticaNeueLight';
}

.b-body-01 {
  font-size: 18px;
  font-family: 'HelveticaNeueMedium';
}

.b-body-02 {
  font-size: 18px;
  font-family: 'HelveticaNeueMedium';
  font-style: italic;
}

.b-body-03 {
  font-size: 16px;
  font-family: 'HelveticaNeueBold';
}

.b-body-04 {
  font-size: 16px;
  font-family: 'HelveticaNeueMedium';
}

.b-body-05 {
  font-size: 16px;
  font-family: 'HelveticaNeueMedium';
  font-style: italic;
}

.b-body-06 {
  font-size: 16px;
  font-family: 'HelveticaNeueLight';
}

.b-body-07 {
  font-size: 14px;
  font-family: 'HelveticaNeueMedium';
}

.b-body-08 {
  font-size: 14px;
  font-family: 'HelveticaNeueMedium';
  font-style: italic;
}

.b-body-09 {
  font-size: 14px;
  font-family: 'HelveticaNeueLight';
}

.b-body-10 {
  font-size: 12px;
  font-family: 'HelveticaNeueRegular'; //Regular
}

.b-body-11 {
  font-size: 11px;
  font-family: 'HelveticaNeueMedium';
  font-style: italic;
}

.b-body-12 {
  font-size: 10px;
  font-family: 'HelveticaNeueLight';
}

.b-body-13 {
  font-size: 20px;
  font-family: 'HelveticaNeueMedium';
}

.b-body-14 {
  font-size: 18px;
  font-family: 'HelveticaNeueLight';
}

.b-body-15 {
  font-size: 16px;
  font-family: 'HelveticaNeueLight';
  font-style: italic;
}

.b-body-16 {
  font-size: 16px;
  font-family: 'HelveticaNeueRegular'; //regular
}

.b-body-17 {
  font-size: 14px;
  font-family: 'HelveticaNeueBold';
}

.b-body-18 {
  font-size: 14px;
  font-family: 'HelveticaNeueRegular';
  font-weight: 700;
  font-style: italic;
}

//Text Color
.text-primary-color {
  color: $primary-color;
}

.text-primary-mid-color {
  color: $primary-mid-color;
}

.text-primary-mid-light-color {
  color: $primary-mid-light-color;
}

.text-primary-dark-color {
  color: $primary-dark-color;
}

.text-primary-dark-blue-color {
  color: $bayer-dark-blue;
}

.text-primary-dark-blue2-color {
  color: $bayer-dark-blue2;
}

.text-secondary-color {
  color: $secondary-color;
}

.text-secondary-dark-color {
  color: $secondary-dark-color;
}

.text-bayer-green {
  color: $bayer-green;
}

.text-bayer-red {
  color: $bayer-red;
}

.text-bayer-dark-red {
  color: $bayer-dark-red;
}

.text-bayer-black {
  color: $bayer-black;
}

.text-bayer-white {
  color: $bayer-white;
}

.background-bayer-white {
  background-color: $bayer-white;
}

.background-primary-mid-dark-color {
  background-color: $primary-mid-dark-color;
}

.text-gray-scale-01 {
  color: $gray-scale-01;
}

.info-orange {
  color: orange;
}

.text-gray-scale-02 {
  color: $gray-scale-02;
}

.text-gray-scale-03 {
  color: $gray-scale-03;
}

.text-gray-scale-04 {
  color: $gray-scale-04;
}

.text-gray-scale-05 {
  color: $gray-scale-05;
}

.text-gray-scale-06 {
  color: $gray-scale-06;
}

.text-gray-scale-07 {
  color: $gray-scale-07;
}

// Button Styles
.btn-bayer-primary,
.btn-bayer-third {
  background-color: $primary-color;
  color: white;
  font-weight: 500;
  font-style: italic;
  border-radius: 0;
  padding: 16px 60px 16px 32px;
  display: inline-block;
  letter-spacing: 0.5px;
  font-size: 14px;
  text-transform: uppercase;
  border: none;
  overflow: hidden;
  line-height: 0.8;
  position: relative;
  height: 45px;
  @include transition();

  &:hover {
    padding-left: 37px;
    padding-right: 55px;
  }

  &:after {
    content: ' ';
    background-color: $primary-dark-color;
    position: absolute;
    width: 50px;
    top: 0;
    left: auto;
    right: -5px;
    transform: skew(-12deg);
    height: 100%;
    z-index: 1;
    @include transition();
  }

  &:hover:after {
    right: -50%;
  }

  &:before {
    font-family: 'Material Icons';
    content: '\e5cc';
    font-size: 24px;
    font-style: normal;
    display: block;
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
    background: transparent no-repeat 50%;
    background-size: 14px 20px;
    pointer-events: none;
    touch-action: none;
    z-index: 2;
    @include transition();
  }

  &:hover:before {
    right: 20px;
  }

  &.small {
    padding: 13px 50px 13px 24px;
    width: 150px;
    height: 38px;

    &:hover {
      padding-left: 29px;
      padding-right: 45px;
    }

    &:after {
      width: 40px;
    }

    &:before {
      right: 5px;
    }

    &:hover:before {
      right: 16px;
    }
  }

  &.medium {
    padding: 13px 50px 13px 24px;
    width: 130px;
    height: 39px;

    &:hover {
      padding-left: 29px;
      padding-right: 45px;
    }

    &:after {
      width: 40px;
    }

    &:before {
      right: 5px;
    }

    &:hover:before {
      right: 16px;
    }
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
}

.btn-bayer-third {
  background-color: $secondary-color;

  &:after {
    background-color: $secondary-dark-color;
  }
}

.btn-bayer-secondary {
  color: $primary-mid-color;
  font-style: italic;
  text-decoration: underline;
  border: none;
  background-color: transparent;
  font-weight: bold;
  @include transition();

  &:hover {
    text-decoration: underline;
  }
}

.btn-bayer-link {
  color: $primary-dark-color;
  text-decoration: none;
  border: none;
  background-color: transparent;
  @include transition();

  &:hover {
    color: $bayer-red;
  }

  &:active {
    color: $primary-color;
  }
}

// Pagenation Style
.pagination-cont {
  padding: 5px 0px;
  color: $primary-dark-color;
  fill: $primary-dark-color;
  margin-top: 5px;
  display: flex;
  align-items: flex-end;

  button {
    border: none;
    background: $secondary-color;
    height: 34px;
    width: 40px;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    img {
      width: 7px;
    }
  }

  .page-count {
    display: flex;
    align-items: center;

    .total-page {
      padding: 0 12px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    text-align: center;
    border-radius: 0;
    height: 34px;
    color: $primary-dark-color;
    min-width: 36px;
    border: 1px solid $primary-dark-color;

    &:focus-visible {
      outline: 0;
      border: 1px solid $bayer-black;
    }
  }
}

app-menu {
  .pagination-cont {
    margin-top: 0;
    padding: 2px 0px;

    button {
      height: 20px;
      width: 24px;
      padding: 0;

      svg {
        height: 18px;
        width: 18px;
      }
    }

    input[type='number'] {
      height: 20px;
      width: 33px;
      min-width: 24px;
      padding: 0;
    }

    .total-page {
      padding: 0 6px !important;
    }
  }
}

//CK Editor
.ck-content {
  background-color: rgb(255, 255, 255);
  border: 1px solid $gray-scale-04 !important;
  border-radius: 0px !important;
  min-height: 150px;
}

//Icons
.b-icon {
  cursor: pointer;
  width: 20px;
}

.seprator-icon {
  width: 3.8px;
  margin: 0 15px;
}

/**Panel Heading*/
.panel-heading {
  text-decoration: underline;
  text-underline-position: under;
}

/**Multimedia tree styling*/
.multimedia-cont {
  .node-content-wrapper-active,
  .node-content-wrapper-focused {
    color: $bayer-green;
  }

  .node-content-wrapper-active,
  .node-content-wrapper.node-content-wrapper-active:hover,
  .node-content-wrapper-active.node-content-wrapper-focused {
    background: none;
  }

  .node-content-wrapper-active,
  .node-content-wrapper-focused,
  .node-content-wrapper:hover {
    box-shadow: none;
  }
}

.autoname-img {
  width: 12px;
  margin-left: 4px;
  margin-bottom: 2px;
}

// scrollbar
/* width */
::-webkit-scrollbar {
  width: 9px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c3bcbc;
  border-radius: 10px;
  border: 2px solid white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #808080;
}

// Chart-data error handling
$count: 1;

@while $count < 50 {
  #google-visualization-errors-all-#{$count} {
    display: none !important;
  }

  $count: $count + 1;
}

google-chart {
  rect {
    stroke: none;
    stroke-width: 0;
  }

  path {
    stroke: rgb(230, 230, 230);
    stroke-width: 1;
    fill-opacity: 1;
    fill: none;
  }

  text[text-anchor='end'] {
    fill: $primary-dark-color !important;
    font-size: 14px !important;
    font-family: 'HelveticaNeueLight' !important;
    font-weight: 300;
  }

  text[text-anchor='start'] {
    fill: $bayer-white !important;
    font-size: 14px !important;
    font-family: 'HelveticaNeueLight' !important;
    font-weight: 300;
  }
}

.line-height {
  line-height: 1.4 !important;
}

.icon {
  cursor: pointer;
}

// To Update All Block Comp - CSS
app-block {
  padding: 10px !important;

  > div > label {
    margin-bottom: -10px !important;
    background-color: $gray-scale-09;
    padding: 0 15px !important;
    margin-left: 15px !important;
  }

  .block {
    app-layout {
      > .row {
        border: 1px solid rgb(16 56 79 / 50%) !important;
        padding-top: 30px !important;
        padding-bottom: 20px;
      }
    }
  }
}
.line-style {
  height: 1px;
  opacity: 0.2;
  background: $bayer-dark-blue2;
  margin: 15px 0px 30px 0;
}

.dashboard-metrics-title {
  font-family: 'HelveticaNeueMedium';
  font-size: 24px;
  font-weight: 700;
}
.dashboard-metrics-sub-title {
  font-family: 'HelveticaNeueMedium';
  font-size: 24px;
  font-weight: 500;
}
// Fix on to remove border on active click
button:focus:not(:focus-visible) {
  outline: 0 !important;
}
.chart-container-vertical {
  width: 547px;
}
